import styled from 'styled-components';

export const ImageStyle = styled.img`
  max-width: auto;
  max-height: 100px;
  margin: 0 auto;
  border: 0;
  margin-bottom: 10px;
  vertical-align: top;
`;

export const ParagraphStyle = styled.p`
  font-size: 21px;
  line-height: 31px;
  color: #929497;
  margin: 0 0 20px 0;
  padding: 0;
  font-family: 'Helvetica-Condensed', arial, sans-serif;
  @media (max-width: 870px) {
    font-size: 21px;
    line-height: 34px;
  }
`;

export const LinkStyle = styled.a`
  text-decoration: none;
  color: #00447c;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
`;

export const Header1 = styled.h1`
  font-size: 46px;
  line-height: 56px;
  padding: 25px 0 0 0;
  margin: 0 0 25px 0;
  font-family: 'MrAlex', arial, sans-serif;
  font-weight: normal !important;
  color: #00539f;
`;

export const Body = styled.div`
  margin: 0 auto !important;
  padding: 10px;
  padding-top: 60px !important;
  text-align: center;
  padding-bottom: 30px;
  margin: 0;
  padding: 0;
  color: #00539f;
  font-family: 'MrAlex', arial, sans-serif;
  font-size: 15px;
  line-height: 130%;
  letter-spacing: 0.02em;

  @media (max-width: 870px) {
    width: 100%;
    margin: 0;
    height: auto;
    padding-top: 40px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 25px;
  }
`;
